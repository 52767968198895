import i18n from '@/i18n'
import moment from 'moment'

const $t = i18n.t

const capitalize = function (value) {
  if (!value) return ''
  return value.toUpperCase()
}

const capitalizeFirstLetter = function (value) {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const formatDashToWhitespace = function (value) {
  if (!value) return ''
  if (value.indexOf('_') < 0 && value.indexOf('-') < 0) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
  const words = value.replace(/_|-/g, ' ')
  return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase()
}

const formatPrice = function (value) {
  if (!value) return 0
  if (value === 0) return 0
  value = value / 100
  return value
}

const formatNumber = function (value) {
  if (!value || value === 0) return 0
  return value.toLocaleString()
}

const formatDate = function (value) {
  if (!value) return ''
  value = moment(value).format('YYYY-MM-DD')
  return value
}

const formatTime = function (value) {
  if (!value) return ''
  return moment(value).fromNow()
}

const formatDateDM = function (value) {
  if (!value) return ''
  value = moment(value).format('DD.MM.YYYY')
  return value
}

function formatDateTime(value, seconds) {
  if (!value) return ''
  if (seconds) return moment(value).format('DD.MM.YYYY HH:mm:ss')
  else return moment(value).format('DD.MM.YYYY HH:mm')
}

const formatDateInString = function (value) {
  if (!value) return ''
  return moment(value).fromNow()
}

const formatDateWithRelative = function (value) {
  const today = moment()
  const yesterday = moment().subtract(1, 'days')

  if (moment(value).isSame(today, 'day')) return $t('shared.label.chat.today')
  else if (moment(value).isSame(yesterday, 'day'))
    return $t('shared.label.yesterday')
  else return moment(value).format('DD MMM YYYY')
}

const formatCurrency = function (value) {
  if (!value) return ''
  if (value === 'EUR') {
    return '€'
  } else {
    return value
  }
}

export {
  capitalize,
  capitalizeFirstLetter,
  formatCurrency,
  formatDashToWhitespace,
  formatDate,
  formatDateDM,
  formatDateInString,
  formatDateTime,
  formatDateWithRelative,
  formatNumber,
  formatPrice,
  formatTime
}
